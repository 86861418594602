import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';

import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Swiper, SwiperSlide } from 'swiper/react';

const SustainabilityRelationship = ({ module }) => {
  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.sustainability-relationship-loop-slider .swiper'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.sustainability-relationship-loop-slider .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <section className="sustainability-relationship-loop-slider lg:py-120 py-60 lg:pb-60 bg-white imgBox-slider">
      <div className="container-fluid-md pr-0 mdscreen:pl-0">
        {module?.heading && (
          <div className="title-black pb-60 mdscreen:pl-20 mdscreen:pb-30">
            <h3>{module.heading}</h3>
          </div>
        )}
        {module?.projects?.length > 0 && (
          <div className="slider-wrapper relative">
            <Swiper
              loop={true}
              slidesPerView={2.2}
              speed={1000}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                  centeredSlides: false,
                  allowTouchMove: true,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 2.2,
                  spaceBetween: 40,
                },
              }}
            >
              {module.projects?.length > 0 &&
                module.projects.map((item, index) => (
                  <SwiperSlide key={index.toString()}>
                    <div className="card-bx">
                      <div className="">
                        <img
                          src={
                            item?.featuredImage?.node.imgixImage.fluid.srcWebp
                          }
                          alt={item?.featuredImage?.node.altText}
                          width="796"
                          height="517"
                          loading="lazy"
                        />
                      </div>
                      <div className="img-content pt-30 smscreen2:text-center mdscreen:pl-20">
                        <Link to={item.uri}>
                          <h4 className="text-black-200">{item?.title}</h4>
                          {item?.categories?.nodes?.length > 0 && (
                            <span className="text-11 text-dark font-400 uppercase leading-22 mb-10 block">
                              {item?.categories?.nodes[0]?.name}
                            </span>
                          )}
                        </Link>
                        {item?.excerpt && (
                          <div className="content text-sm">
                            {parse(item?.excerpt)}
                          </div>
                        )}
                        <Link to={item.uri} className="link-btn">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              <div className="block swiper-arrow z-9 flex items-center justify-between w-full top-75-per translate-y-minus_50 px-20">
                <a
                  className="button-prev cursor-pointer"
                  onClick={handlePrevClickStay}
                ></a>
                <a
                  className="button-next cursor-pointer"
                  onClick={handleNextClickStay}
                ></a>
              </div>
            </Swiper>
          </div>
        )}
      </div>
    </section>
  );
};

export default SustainabilityRelationship;

export const SustainabilityRelationshipFragment = graphql`
  fragment SustainabilityRelationshipFragment on WpPage_Flexiblecontent_FlexibleContent_SustainabilityRelationship {
    fieldGroupName
    heading
    projects {
      ... on WpProperty {
        __typename
        id
        categories {
          nodes {
            id
            name
          }
        }
        excerpt
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 796
                maxHeight: 517
                imgixParams: { w: 890, h: 647, fit: "crop" }
              ) {
                srcWebp
              }
            }
          }
        }
        uri
        title
      }
    }
  }
`;

export const SustainabilityRelationshipPropertyFragment = graphql`
  fragment SustainabilityRelationshipPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_SustainabilityRelationship {
    # content
    fieldGroupName
    heading
    projects {
      ... on WpProperty {
        __typename
        id
        categories {
          nodes {
            id
            name
          }
        }
        excerpt
        featuredImage {
          node {
            altText
            imgixImage {
              fluid(
                maxWidth: 796
                maxHeight: 517
                imgixParams: { w: 890, h: 647, fit: "crop" }
              ) {
                srcWebp
              }
            }
          }
        }
        uri
        title
      }
    }
  }
`;
