import { graphql, Link, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const FoundationLoop = ({ module }) => {
  const pageSize = 6;
  const [displayedFoundationCount, setDisplayedFoundationCount] =
    useState(pageSize);

  const [selectedFilter, setSelectedFilter] = useState('all');

  const foundationCategories = useStaticQuery(graphql`
    query foundationCategories {
      allWpCategory(filter: { wpParent: { node: { properties: {} } } }) {
        nodes {
          name
          id
        }
      }
    }
  `);

  const onFilterChange = (e) => {
    setSelectedFilter(e.target.value);
  };

  const onLoadMoreClick = () => {
    setDisplayedFoundationCount(displayedFoundationCount + pageSize);
  };
  let foundations = [];
  foundations =
    selectedFilter === 'all'
      ? module?.foundation
      : module?.foundation?.filter((item) => {
          let isCat = item?.categories?.nodes.filter(
            (catItem) => catItem.id === selectedFilter
          );
          if (isCat?.length > 0) {
            return true;
          }
          return false;
        });

  const handleNextClickStay = () => {
    const swiper = document.querySelector(
      '.foundation-loop-slider .swiper'
    ).swiper;
    swiper.slideNext();
  };
  const handlePrevClickStay = () => {
    const swiper = document.querySelector(
      '.foundation-loop-slider .swiper'
    ).swiper;
    swiper.slidePrev();
  };

  return (
    <section
      className={`foundation-loop-slider lg:py-60 py-30 pb-60 lg:pb-60 ${module.extraClass}`}
    >
      {module.variation === 'Grid' ? (
        <div className="container-fluid">
          {module?.hideFilter === 'No' && (
            <div className="container m-auto">
              <div className="filter-list text-center lg:mb-60 mb-30 relative mdscreen:pb-10 mdscreen:border-b-[1px] mdscreen:border-b-[#666366]/40">
                <select onChange={onFilterChange}>
                  <option value="all">All Projects</option>
                  {foundationCategories?.allWpCategory?.nodes.map((item, i) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
            </div>
          )}
          <div className="flex flex-wrap lg:mx-minus-20 lg:gap-y-20 gap-y-10 ">
            {foundations &&
              foundations.length > 0 &&
              foundations
                ?.slice(0, displayedFoundationCount)
                .map((item, index) => {
                  return (
                    <div
                      className={`lg:w-6/12 w-full lg:px-20 fade-img`}
                      key={item?.id || index.toString()}
                    >
                      <Link to={item.uri}>
                        <img
                          src={item?.featuredImage?.node.imgixImage.url}
                          alt={item?.featuredImage?.node.altText || ''}
                          className="w-full"
                          loading="lazy"
                        />
                      </Link>

                      <div className="img-content pt-30 max-w-540">
                        <Link to={item.uri}>
                          {item?.preheading && (
                            <span className="text-13 text-dark font-400 uppercase leading-22 mb-10 inline-block font-preheaders">
                              {item.preheading}
                            </span>
                          )}
                          <h4 className="text-black-200"> {item.title}</h4>
                          {item?.categories?.nodes?.length > 0 && (
                            <span className="text-11 text-dark font-400 uppercase leading-22 mb-10 block">
                              {item?.categories?.nodes[0]?.name}
                            </span>
                          )}
                        </Link>
                        {item?.excerpt && (
                          <div className="content text-sm mb-15">
                            {parse(item?.excerpt)}
                          </div>
                        )}
                        <Link to={item.uri} className="link-btn">
                          Read More
                        </Link>
                      </div>
                    </div>
                  );
                })}
          </div>
          {foundations &&
            displayedFoundationCount < foundations.length &&
            foundations.length > 1 && (
              <div className="btn-custom text-center justify-center cursor-pointer lg:mt-80 mt-40">
                <a
                  onClick={onLoadMoreClick}
                  className="button button-transparent bg-[#e2e2e2]"
                >
                  Load More
                </a>
              </div>
            )}

          {foundations?.length === 0 && (
            <div className="no-record-found">
              <div className="container-fluid-md">
                <div className="bg-white shadow-card lg:py-120 py-60">
                  <div className="text-center">
                    <h2>No Available Foundations</h2>
                    <div className="content mt-20 max-w-500 m-auto">
                      <p>
                        There don't seem to be any available foundations at the
                        moment. please check in again a later date.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="container-fluid-md imgBox-slider pr-0 mdscreen:pl-0">
          {module?.heading && (
            <div className="title-black pb-60 mdscreen:pl-20 mdscreen:pb-30">
              <h3>{module.heading}</h3>
            </div>
          )}
          {module?.foundation?.length > 0 && (
            <div className="slider-wrapper relative">
              <Swiper
                loop={true}
                slidesPerView={2.2}
                speed={1000}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    centeredSlides: false,
                    allowTouchMove: true,
                  },
                  640: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2.2,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 2.2,
                    spaceBetween: 40,
                  },
                }}
              >
                {module.foundation.map((item, index) => (
                  <SwiperSlide key={index.toString()}>
                    <div className="card-bx">
                      <div>
                        <img
                          src={item?.featuredImage?.node.imgixImage.url}
                          alt={item.featuredImage.node.altText}
                          className="w-full"
                          loading="lazy"
                        />
                        <div className="swiper-arrow z-9 flex items-center justify-between w-full top-50-per translate-y-minus_50 px-20">
                          <a
                            className="button-prev cursor-pointer"
                            onClick={handlePrevClickStay}
                          ></a>
                          <a
                            className="button-next cursor-pointer"
                            onClick={handleNextClickStay}
                          ></a>
                        </div>
                      </div>
                      <div className="img-content pt-30 smscreen2:text-center mdscreen:pl-20">
                        <h4 className="text-black-200">{item?.title}</h4>
                        {item?.categories?.nodes?.length > 0 && (
                          <span className="text-[11px] text-dark font-400 uppercase leading-22 mb-10 block">
                            {item?.categories?.nodes[0]?.name}
                          </span>
                        )}
                        {item?.excerpt && (
                          <div className="content text-sm mb-15">
                            {parse(item?.excerpt)}
                          </div>
                        )}
                        <Link to={item.uri} className="link-btn">
                          Read More
                        </Link>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default FoundationLoop;
export const FoundationLoopFragment = graphql`
  fragment FoundationLoopFragment on WpPage_Flexiblecontent_FlexibleContent_FoundationLoop {
    # content
    fieldGroupName
    heading
    extraClass
    variation
    foundation {
      ... on WpProperty {
        id
        title
        uri
        excerpt
        uri
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            id
            altText
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }
      }
    }
  }
`;
export const FoundationFoundationLoopFragment = graphql`
  fragment FoundationFoundationLoopFragment on WpProperty_Flexiblecontent_FlexibleContent_FoundationLoop {
    # content
    fieldGroupName
    heading
    variation
    foundation {
      ... on WpProperty {
        id
        title
        excerpt
        uri
        categories {
          nodes {
            name
            id
          }
        }
        featuredImage {
          node {
            id
            altText
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }
      }
    }
  }
`;
export const FoundationLoopPropertyFragment = graphql`
  fragment FoundationLoopPropertyFragment on WpProperty_Flexiblecontent_FlexibleContent_FoundationLoop {
    # content
    fieldGroupName
    heading
    variation
    hideFilter
    foundation {
      ... on WpProperty {
        id
        id
        uri
        title
        excerpt
        categories {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            id
            altText
            imgixImage {
              url(imgixParams: { w: 650, h: 473, q: 95, fit: "crop" })
            }
            uri
            title
          }
        }
      }
    }
  }
`;
