import { graphql } from 'gatsby';
import React from 'react';
import FoundationLoop from '../components/FoundationLoop';
import ImageContent from '../components/ImageContent';
import IntroContent from '../components/IntroContent';
import LodgeMenu from '../components/LodgeMenu';
import SubMenu from '../components/SubMenu';
import SustainabilityRelationship from '../components/SustainabilityRelationship';

const FoundationModules = (props) => {
  const modules = props.modules.flexibleContent;
  const microSitename = props.microSitename;
  const { location } = props;
  const components = {
    IntroContent,
    SubMenu,
    ImageContent,
    LodgeMenu,
    FoundationLoop,
    SustainabilityRelationship,
  };
  return (
    <>
      {modules
        ? modules.map((module, i) => {
            const moduleName = module.__typename.replace(
              'WpProperty_Flexiblecontent_FlexibleContent_',
              ''
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                microSitename: microSitename,
                i,
              })
            );
          })
        : null}
    </>
  );
};

export default FoundationModules;

export const FoundationModulesFragment = graphql`
  fragment FoundationModulesFragment on WpProperty {
    flexibleContent {
      flexibleContent {
        __typename
        ...FoundationIntroContentFragment
        ...FoundationImageContentFragment
        ...FoundationFoundationLoopFragment
        ...SustainabilityRelationshipPropertyFragment
      }
    }
  }
`;
